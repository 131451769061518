<template>
  <system-template>
    <bread-crumb :items="itemsBreadCrumb"></bread-crumb>
  </system-template>
  <!-- <pre>{{code}}</pre> -->
  <div class="container-logo">
      <img :src="dashBoardImage" alt="dashBoardImage" />
  </div>
  <div class="btn-container">
      <v-btn
        class="mt-2"
        color="primary"
        value="save"
        @click="handleClick"
        >Begin documenting
      </v-btn>
  </div>
  </template>
<script>

import SystemTemplate from '@/templates/SystemTemplate.vue';
import BreadCrumb from '@/components/BreadCrumb.vue';
import dashBoardImage from '@/assets/dashboard_image.jpg';
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'DashboardView',
  components: {
    SystemTemplate,
    BreadCrumb,
  },
  data() {
    return {
      dashBoardImage,
      mode: 'edit',
      code: {
        header: [
          {
            key: 'Content-Type',
            value: 'application/json',
            readonly: true,
            required: true,
            active: true,
          },
          {
            key: 'Auth',
            value: 'Bearer ',
            readonly: false,
            required: true,
            active: true,
          },
        ],
        id: 2,
        protocol: 'GET',
        url: 'https://jsonplaceholder.typicode.com/todos/-1',
        body: '',
        active: true,
        createdAt: '2022-05-23T19:33:27.000Z',
        updatedAt: '2022-05-24T20:06:41.000Z',
        deletedAt: null,
        contentId: 3,
      },
      itemsBreadCrumb: [
        {
          text: 'Root',
          disabled: true,
          href: '#',
        },
      ],
    };
  },
  computed: {
    ...mapState(['showBack']),
  },
  methods: {
    ...mapMutations(['setBackButtonVisibility']),
    handleClick() {
      this.$router.push({ path: '/categories' });
    },
  },
  beforeUnmount() {
    this.setBackButtonVisibility(true);
  },
  mounted() {
    this.setBackButtonVisibility(false);
  },
};
</script>
<style scoped lang="scss">

.btn-container {
  display: flex;
  justify-content: center;
  margin: 15px auto;
}

.container-logo{

    display: flex;
    justify-content: center;
    align-items: center;

    img{
      width:90%;
      max-width: 1000px;
    }
}
</style>
