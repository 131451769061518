<template>
  <div class="not-found">
    <!-- <v-container>
      <v-layout row wrap>
        <v-flex xs12>
          <v-card height="400" color="transparent" flat>
            <v-img
              :lazy-src="Logo"
              max-height="150"
              max-width="250"
              :src="Logo"
            ></v-img>
            <div class="display-3 mt-5">Page not found.</div>
            <div class="grey--text lighten-5">
              The page you are trying to get to never existed in this reality,
              or has migrated to a parallel universe.
            </div>
            <div class="paragraph-text mt-2">
              <br />
              <br />Try going back to home page and repeating your action. Or,
              contact helpdesk for support.
            </div>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container> -->
    <v-container class="error-container">
      <div class="fof">
        <h1>Error 404</h1>
      </div>
      <div class="btn-container">
      <v-btn
        class="mt-2"
        color="primary"
        value="save"
        @click="handleClick"
        >Go Home
      </v-btn>
  </div>
    </v-container>
  </div>
</template>

<script>
import Logo from '@/assets/logo.svg';

export default {
  name: 'AuthView',
  data() {
    return {
      Logo,
    };
  },
  methods: {
    handleClick() {
      this.$router.push({ path: '/' });
    },
  },
};
</script>
<style lang="scss" scoped>

  .not-found {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: calc(100vh - 150px);
    justify-content: center;
    align-items: center;
  }
  .btn-container {
    display: flex;
    justify-content: center;
    margin: 15px auto;
  }
  .fof, .error-container {
    display: flex;
    // flex: 1;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
  }
  .paragraph-text {
    font-size: 18px;
  }
  *{
    transition: all 0.6s;
}

// html {
//     height: 100%;
// }

body{
    font-family: 'Lato', sans-serif;
    color: #888;
    margin: 0;
}

#main{
    display: table;
    width: 100%;
    height: 100vh;
    text-align: center;
}

.fof{
  display: table-cell;
  vertical-align: middle;
}

.fof h1{
  font-size: 50px;
  display: inline-block;
  padding-right: 12px;
  animation: type .5s alternate infinite;
  margin: 0 auto;
  text-align: center;
}

@keyframes type{
  from{box-shadow: inset -3px 0px 0px #888;}
  to{box-shadow: inset -3px 0px 0px transparent;}
}
</style>
