<template>
  <system-template>
    <bread-crumb :items="itemsBreadCrumb"></bread-crumb>
    <div>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-container>
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="category.name"
                name="name"
                label="name"
                type="text"
                placeholder="name"
                required
                :rules="nameRules"
              ></v-text-field>

              <v-checkbox
                v-model="category.active"
                label="Enabled"
                color="success"
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col class="d-flex justify-center" cols="12" md="12">
              <v-btn
              class="mt-2"
              color="primary"
              value="edit"
              @click="validate"
              :loading="loading"
              :disabled="!valid"
              >Edit</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </div>
  </system-template>
</template>
<script>
import SystemTemplate from '@/templates/SystemTemplate.vue';
import BreadCrumb from '@/components/BreadCrumb.vue';
import API from '@/lib/Api';

export default {
  name: 'CategoryEdit',
  props: ['id'],
  components: {
    SystemTemplate,
    BreadCrumb,
  },
  data: () => ({
    itemsBreadCrumb: [
      {
        text: 'Root',
        disabled: false,
        href: '/dashboard',
      },
      {
        text: 'Categories',
        disabled: false,
        href: '/categories',
      },
      {
        text: 'Edit',
        disabled: true,
        href: '#',
      },
    ],
    valid: true,
    loading: false,
    nameRules: [
      (v) => !!v || 'Name is required',
      (v) => v.length <= 250 || 'Name must be less than 250 characters',
    ],
    category: {},
  }),
  methods: {
    async validate() {
      const { valid } = await this.$refs.form.validate();
      this.valid = !valid;
      if (valid) {
        this.save();
      }
    },
    async save() {
      this.loading = true;
      const result = await API.patch(`/category/${this.id}`, this.category);
      if (!result.success) {
        console.log(result?.message ?? 'An error ocurred');
        this.$toast.error(
          result?.message ?? 'An error ocurred',
          {
            position: 'top',
          },
        );
        this.valid = true;
        return;
      }
      this.loading = false;
      this.$refs.form.reset();
      this.valid = true;
      this.$router.push('/categories');
      this.$toast.success(
        'Category updated successfully',
        {
          position: 'top',
        },
      );
    },
    async getCategory() {
      try {
        const { data } = await API.get(`/category/${this.id}`);
        this.category = data;
      } catch (error) {
        console.error(error);
      }
    },
  },
  mounted() {
    this.getCategory();
  },
};
</script>
<style scoped>
</style>
