import { createStore } from 'vuex';

export default createStore({
  state() {
    return {
      showBack: true,
    };
  },
  getters: {
  },
  mutations: {
    setBackButtonVisibility(state, visible = true) {
      state.showBack = visible;
    },
  },
  actions: {
  },
  modules: {
  },
});
