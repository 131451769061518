import { createRouter, createWebHistory } from 'vue-router';

import DocumentationView from '../views/DocumentationView.vue';
import AboutView from '../views/AboutView.vue';
import AuthView from '../views/AuthView.vue';
import DashboardView from '../views/DashboardView.vue';
import CategoriesView from '../views/CategoriesView.vue';
import CategoryEdit from '../views/CategoryEdit.vue';
import SubCategoryEdit from '../views/SubCategoryEdit.vue';
import SubCategoriesView from '../views/SubCategoriesView.vue';
import ArticlesView from '../views/ArticlesView.vue';
import ArticleEdit from '../views/ArticleEdit.vue';
import ContentView from '../views/ContentView.vue';
import NotFound from '../views/NotFound.vue';

const routes = [
  // external routes --------------------------------------------------------
  {
    path: '/',
    redirect: '/docs',
  },
  {
    path: '/docs',
    name: 'Home',
    component: DocumentationView,
  },
  {
    path: '/docs/:category?/:subcategory?/:article?',
    name: 'Home',
    component: DocumentationView,
    props: true,
  },
  {
    path: '/about',
    name: 'About',
    component: AboutView,
  },
  {
    path: '/login',
    name: 'Login',
    component: AuthView,
  },
  // external routes --------------------------------------------------------

  {
    path: '/:catchAll(.*)',
    component: NotFound,
    name: 'NotFound',
  },

  // authenticated routes --------------------------------------------------------
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardView,
    meta: { requiresAuth: true },
  },
  {
    path: '/categories',
    name: 'View Categories',
    component: CategoriesView,
    meta: { requiresAuth: true },
  },
  {
    path: '/category/:id',
    name: 'Edit Category',
    component: CategoryEdit,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/category/:id/subcategories',
    name: 'View Subcategories',
    component: SubCategoriesView,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/category/:idCategory/subcategory/:idSubcategory',
    name: 'Edit Subcategory',
    component: SubCategoryEdit,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/category/:idCategory/subcategory/:idSubcategory/articles',
    name: 'View Articles',
    props: true,
    component: ArticlesView,
    meta: { requiresAuth: true },
  },
  {
    path: '/category/:idCategory/subcategory/:idSubcategory/article/:idArticle',
    name: 'Edit Article',
    props: true,
    component: ArticleEdit,
    meta: { requiresAuth: true },
  },
  {
    path: '/category/:idCategory/subcategory/:idSubcategory/article/:idArticle/content',
    name: 'View Content - Article',
    props: true,
    component: ContentView,
    meta: { requiresAuth: true },
  },
  {
    path: '/category/:idCategory/content',
    name: 'View Content - Category',
    props: true,
    component: ContentView,
    meta: { requiresAuth: true },
  },

  // authenticated routes --------------------------------------------------------
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const isAuthenticated = localStorage.getItem('auth');
    if (!isAuthenticated || isAuthenticated === null) {
      // console.log(isAuthenticated, 'is not logged in');
      next({
        path: '/login',
        query: { redirect: to.fullPath },
      });
    } else {
      if (to.path === '/login') {
        next({
          path: '/dashboard',
          query: { redirect: to.fullPath },
        });
      }
      // console.log(isAuthenticated, 'is logged in');
      next();
    }
  } else {
    next();
  }
});

export default router;
