<template>
    <div class="d-flex justify-space-between align-center">
      <v-btn
        @click="goBack"
        variant="text"
        icon="mdi-arrow-left"
        color="black"
        class="back-button-arrow"
        :class="{hide: !showBack}"
      ></v-btn>

      <v-breadcrumbs :items="items">
        <template v-slot:text="{ item }">
          {{ item.text.toUpperCase() }}
        </template>
      </v-breadcrumbs>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'BreadCrumb',
  props: ['items'],
  components: {
  },
  data: () => ({
  }),
  watch: {
    'this.$route': {
      handler(newValue) {
        console.log(newValue);
      },
    },
  },
  computed: {
    ...mapState(['showBack']),
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
  mounted() {
    // this.$root.$on('hideBackButton', () => { this.showBack = false; });
    // this.$root.$on('showBackButton', () => { this.showBack = true; });
  },
};
</script>

<style lang="scss" scoped>
.back-button-arrow {
  &.hide {
    opacity: 0;
    pointer-events: none;
  }
}
</style>
